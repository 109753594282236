//
const config = {
  //common
  logo: process.env.PUBLIC_URL + "/media/common/logo.svg",
  logoPNG: process.env.PUBLIC_URL + "/media/common/logo.png",
  music: process.env.PUBLIC_URL + "/media/audio/music.mp3",
  //showreel
  showreel: {
    url: "https://www.youtube.com/watch?v=YP9w8wfEFQY",
  },

  //home parallax
  homeParallax: {
    layer1: process.env.PUBLIC_URL + "/media/home/parallax/layer-1.webp",
    layer2: process.env.PUBLIC_URL + "/media/home/parallax/layer-2.webp",
    layer1Mob: process.env.PUBLIC_URL + "/media/home/parallax/layer-1-mob.webp",
    layer2Mob: process.env.PUBLIC_URL + "/media/home/parallax/layer-2-mob.webp",
  },

  //home
  homepage: {
    /* manifesto */
    s2Bg: process.env.PUBLIC_URL + "/media/home/s2/bg.webp",
    s2BgMob: process.env.PUBLIC_URL + "/media/home/s2/bg-mob.webp",
    /* we are disruptive */
    s3Bg: process.env.PUBLIC_URL + "/media/home/s3/bg.webp",
    s3BgMob: process.env.PUBLIC_URL + "/media/home/s3/bg-mob.webp",
    /* we are creative */
    s4Bg: process.env.PUBLIC_URL + "/media/home/s4/bg.webp",
    s4BgMob: process.env.PUBLIC_URL + "/media/home/s4/bg-mob.webp",
    /* we are creative */
    s5Bg: process.env.PUBLIC_URL + "/media/home/s5/bg.webp",
    s5BgMob: process.env.PUBLIC_URL + "/media/home/s5/bg-mob.webp",
    /* we are creative */
    s6Bg: process.env.PUBLIC_URL + "/media/home/s6/bg.webp",
    s6BgMob: process.env.PUBLIC_URL + "/media/home/s6/bg-mob.webp",
    /* we are creative */
    s7Bg: process.env.PUBLIC_URL + "/media/home/s7/bg.webp",
    s7BgMob: process.env.PUBLIC_URL + "/media/home/s7/bg-mob.webp",
  },

  //about
  about: {
    bio1: process.env.PUBLIC_URL + "/media/about/philip.webp",
    bio1Mob: process.env.PUBLIC_URL + "/media/about/philip.webp",
    bio2: process.env.PUBLIC_URL + "/media/about/omar.webp",
    bio2Mob: process.env.PUBLIC_URL + "/media/about/omar.webp",
    bio3: process.env.PUBLIC_URL + "/media/about/gus.webp",
    bio3Mob: process.env.PUBLIC_URL + "/media/about/gus.webp",
    s4Bg: process.env.PUBLIC_URL + "/media/about/countries-bg.webp",
    s4BgMob: process.env.PUBLIC_URL + "/media/about/countries-bg.webp",
    s4Fg: process.env.PUBLIC_URL + "/media/about/countries-fg.webp",
    s4FgMob: process.env.PUBLIC_URL + "/media/about/countries-fg.webp",
  },

  //services
  services: {
    s1Bg: process.env.PUBLIC_URL + "/media/services/s1/bg.webp",
    s1BgMob: process.env.PUBLIC_URL + "/media/services/s1/bg-mob.webp",
  },

  //work
  work: {
    s1Bg: process.env.PUBLIC_URL + "/media/work/s1/bg.webp",
    s1BgMob: process.env.PUBLIC_URL + "/media/work/s1/bg-mob.webp",
  },

  //aside
  aside: {
    hopscotch: process.env.PUBLIC_URL + "/media/aside/hopscotch.png",
    manifesto: process.env.PUBLIC_URL + "/media/aside/manifesto.png",
    weAre: process.env.PUBLIC_URL + "/media/aside/we-are.png",
    disru: process.env.PUBLIC_URL + "/media/aside/disru.png",
    ptive: process.env.PUBLIC_URL + "/media/aside/ptive.png",
    crea: process.env.PUBLIC_URL + "/media/aside/crea.png",
    tive: process.env.PUBLIC_URL + "/media/aside/tive.png",
    stra: process.env.PUBLIC_URL + "/media/aside/stra.png",
    tegic: process.env.PUBLIC_URL + "/media/aside/tegic.png",
    integ: process.env.PUBLIC_URL + "/media/aside/integ.png",
    rated: process.env.PUBLIC_URL + "/media/aside/rated.png",
    story: process.env.PUBLIC_URL + "/media/aside/story.png",
    tellers: process.env.PUBLIC_URL + "/media/aside/tellers.png",
    what: process.env.PUBLIC_URL + "/media/aside/what.png",
    we: process.env.PUBLIC_URL + "/media/aside/we.png",
    do: process.env.PUBLIC_URL + "/media/aside/do.png",
    our: process.env.PUBLIC_URL + "/media/aside/our.png",
    wo: process.env.PUBLIC_URL + "/media/aside/wo.png",
    rk: process.env.PUBLIC_URL + "/media/aside/rk.png",
  },
};

export default config;
