import React, { useState, useEffect, createContext } from "react";

//create a context instance
export const NavigationContext = createContext();

//NavigationContextProvider
const NavigationContextProvider = (props) => {
  //define global states
  const [navOpen, setNavOpen] = useState(false);

  //
  useEffect(() => {
    if (navOpen) {
      //
      document.documentElement.setAttribute("data-navigation", "opened");
    } else {
      //
      document.documentElement.setAttribute("data-navigation", "closed");
    }
    return () => {};
  }, [navOpen]);

  //
  const toggleNavigation = (value) => {
    //the correct way to update a state value is through its previous value ;)
    switch (value) {
      case "open":
        setNavOpen(true);
        break;
      case "close":
        setNavOpen(false);
        break;
      case "close-scroll-top":
        //close nav
        setNavOpen(false);
        //scroll to top on page change
        window.scrollTo({ top: 0, behavior: "auto" });

        break;
      default:
    }
  };

  //render the provider
  return (
    <NavigationContext.Provider value={{ navOpen, toggleNavigation }}>
      {props.children}
    </NavigationContext.Provider>
  );
};

export default NavigationContextProvider;
