import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

//styles
import "./styles/App.css";

//import Global contexts
import MusicContextProvider from "./contexts/MusicContext";
import NavigationContextProvider from "./contexts/NavigationContext";
import ScrollRigContextProvider from "./contexts/ScrollRigContext";
import { ParallaxProvider } from "react-scroll-parallax";

//components
import Preloader from "./components/Preloader";

//lazy Components / Routes - views import
const Header = lazy(() => import("./views/layout/Header"));
const Navigation = lazy(() => import("./views/layout/Navigation"));
const Footer = lazy(() => import("./views/layout/Footer"));
const MusicPlayer = lazy(() => import("./components/MusicPlayer"));

//views
const Home = lazy(() => import("./views/Home"));
const About = lazy(() => import("./views/About"));
const Work = lazy(() => import("./views/Work/Work"));
const Services = lazy(() => import("./views/Services"));
const Contact = lazy(() => import("./views/Contact"));
const ErrorPage = lazy(() => import("./views/ErrorPage"));

//app functional component
function App() {
  //render
  return (
    <Suspense fallback={<Preloader status="active" />}>
      <MusicContextProvider>
        <NavigationContextProvider>
          <ScrollRigContextProvider>
            <ParallaxProvider>
              <Router>
                {/* App */}
                <div className="App">
                  {/* fixed content */}
                  <Header />
                  <Navigation />
                  <Routes>
                    <Route path="*" element={<ErrorPage />} />
                    <Route path="/" element={<Home />} />
                    <Route path="/about" element={<About />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/work" element={<Work />} />
                    <Route path="/contact" element={<Contact />} />
                  </Routes>
                  <MusicPlayer />
                  <Footer />
                  {/* </ScrollRig> */}
                  {/* dim bg */}
                  <div className="dim-bg transition-medium"></div>
                </div>
              </Router>
            </ParallaxProvider>
          </ScrollRigContextProvider>
        </NavigationContextProvider>
      </MusicContextProvider>
    </Suspense>
  );
}

export default App;
